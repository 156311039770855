.Sidebar{
	display: flex;
	flex-direction: column;
	flex:0.35;
	background-color: #ffffff;
	border-right: 1px solid lightgray;
}


.Sidebar_header{
	display: flex;
	justify-content: space-between;
	padding:20px;
	max-height: 50px;
	background-color:#dcf8c6;
}

.Sidebar_chats{
	overflow: scroll;
	
}

.profileImg{
	height: 45px;
	width: 45px;
	border-radius: 50%;
}
.useInfo{
	display: flex;
	align-items: center;
}
.userName{
    font-weight: bold;
    margin-left: 20px;
}