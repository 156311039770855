.Chat {
  flex: 0.65;
  display: flex;
  flex-direction: column;
}

.Chat_header {
  height: 65px;
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: #dcf8c6;
}

.Chat_name {
  color: blue;
  font-weight: bold;
}

.message {
  font-weight: bold;
  margin-right: 10px;
}

.Chat_time {
  font-size: xx-small;
  color: grey;
}

.Chat_body {
  flex: 1;
  background-color: #cce0cc;
  overflow: scroll;
  padding: 10px 0;
}

.Chat_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: #cce0cc;
}

.Chat_footer > form {
  flex: 1;
  display: flex;
  padding: 10px;
}
.Chat_footer > form > input {
  font-size: 16px;
  flex: 1;
  border: none;
  padding-left: 15px;
  border-radius: 20px;
  height: 40px;
}

.Chat_footer > form > button {
  display: none;
}

.Chat_message {
  position: relative;
  font-size: 16px;
  padding: 5px;
  background-color: #ffffff;
  width: fit-content;
  border-radius: 10px;
  margin: 10px;
}

.Chat_reciever {
  margin-left: auto;
  background-color: #dcf8c6;
}
